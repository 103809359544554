import React, {useState} from 'react';
import './navbar.css';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
import logo from '../../assets/wid_logo.png'

const Menu = () => (
  <>
    <a href="https://www.facebook.com/"><p>Home</p></a>
    <a href="https://www.facebook.com/"><p>What I Do</p></a>
    <a href="https://www.facebook.com/"><p>About</p></a>
    <a href="https://www.facebook.com/"><p>Skills</p></a>
    <a href="https://www.facebook.com/"><p>Portfolio</p></a>
  </>
)

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false)

  return (
      <div className='wid__navbar'>
        <div className='wid__navbar-container wid__container'>
          <div className='wid__navbar-links'>
            <div className='wid__navbar-links_logo'>
            <a href="#home"><img src={logo} alt="Logo Putu Widnyana Santika"/></a>
            </div>
          </div>
          <div className='wid__navbar-menu'>
            <div className='wid__navbar-menu_container'>
              <Menu/>
            </div>
          </div>
          <div className='wid__navbar-phone'>
            { toggleMenu 
              ? <RiCloseLine color="#464646" size={27} onClick={() => setToggleMenu(false)}/>
              : <RiMenu3Line color="#464646" size={27} onClick={() => setToggleMenu(true)}/>
            }
          </div>
          {
            toggleMenu && (
              <div className='wid__navbar-phone_container scale-up-top'>
                <div className='wid__navbar-phone_container-links scale-up-top'>
                  <Menu/>
                </div>
              </div>
            )
          }
        </div>
      </div>
  )
}

export default Navbar