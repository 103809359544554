import React, { Component } from 'react';
import './hero.css';
import heroImage from "../../assets/me.png"

const Hero = () => {
    return(
        <div className='wid__hero'>
            <div className='wid__hero-container wid__container'>
                <div className='wid__hero-content'>
                    <h1>I’m Widnyana Santika</h1>
                    <h2>a Software Engineer</h2>
                    <p> 
                        I am a fulltime Java Software Engineer at Hitachi Energy. I passionate to explore more experience and technology.
                        I love web design, web developer and photography as side project.
                    </p>
                    <button>View Portfolio</button>
                </div>
                <div className='wid__hero-image'> 
                 <img src={heroImage} alt="Putu Widnyana Santika Character"/>
                </div>
            </div>
        </div>
    )
}

export default Hero;