import React, { Component } from 'react';

import { Navbar } from './components';
import {Hero, Todo, Footer} from './containers'
import './App.css';

const App = () => {
    return (
        <div>
            <Navbar/>
            <Hero/>
        </div>
    )
}

export default App;